<template>
    <div class="ai-score-progress mr-3" v-if="showAiScore">
        <div class="progress" v-if="progressData.score !== null">
            <div class="progress-bar" role="progressbar" :style="`width: ${progressData.score}%; background-color: ${colors[progressData.key]}`" :aria-valuenow="progressData.score" aria-valuemin="0" aria-valuemax="100">
                {{ progressData.score }}%
            </div>
        </div>
        <div class="user-chart-detail" :class="{'pipeline': isPipeline, 'in-bottom': showInBottom}" v-if="applicant.scores.final_score">
            <ul>
                <li v-for="key in scoreKeys" v-if="applicant.scores[key] !== null">
                    {{$t(`${labels[key]}`)}}: <strong :style="`color:${colors[key]}`">{{ applicant.scores[key] }}%</strong>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { AI_SCORE_COLORS } from "../../extra/constants";
import {mapState} from "vuex";

export default {
    name: "AiScoreProgressBar",

    props: {
        applicant: {
            type: Object,
            required: true
        },
        sortBy: {
            type: String,
            default: AI_SCORE_COLORS.TOTAL
        },
        isPipeline: {
            type: Boolean,
            default: false
        },
        showInBottom: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            colors : {
                quiz: AI_SCORE_COLORS.QUIZ,
                education: AI_SCORE_COLORS.EDUCATION,
                skill: AI_SCORE_COLORS.SKILLS,
                experience: AI_SCORE_COLORS.EXPERIENCE,
                final_score: AI_SCORE_COLORS.TOTAL,
            },

            labels : {
                education: "Education",
                experience: "Experience",
                final_score: "Total",
                quiz: "Quiz",
                skill: "Skills"
            },
        }
    },

    computed: {
        ...mapState(['company']),
        scoreKeys(){
            return Object.keys(this.colors);
        },

        progressData() {
            let sortBy = this.sortBy || "SORT_BY_TOTAL_AI_SCORE";

            let data = {
                key : 'final_score',
                score : this.applicant.scores.final_score
            };

            switch (sortBy) {
                case "SORT_BY_TOTAL_AI_SCORE":
                    data = {
                        key : 'final_score',
                        score : this.applicant.scores.final_score
                    };
                    break;
                case "SORT_BY_SKILL_MATCH":
                    data = {
                        key : 'skill',
                        score : this.applicant.scores.skill
                    };
                    break;
                case "SORT_BY_EDUCATION_MATCH":
                    data = {
                        key : 'education',
                        score : this.applicant.scores.education
                    };
                    break;
                case "SORT_BY_EXPERIENCE_MATCH":
                    data = {
                        key : 'experience',
                        score : this.applicant.scores.experience
                    };
                    break;
                case "SORT_BY_QUIZ_SCORE":
                    data = {
                        key : 'quiz',
                        score : this.applicant.scores.quiz
                    };
                    break;
            }
            return data;
        },

        showAiScore(){
            return this.company.showAiScore;
        }
    }
}
</script>

<style scoped>
    .ai-score-progress {
        position: relative;
        cursor: pointer;
    }

    .ai-score-progress .user-picture {
        position: absolute;
        top: 10px;
        left: 23px;
        width: 100px !important;
        height: 100px !important;
    }

    .ai-score-progress .user-chart-detail {
        position: absolute;
        background: #fff;
        padding: 10px 15px;
        font-size: .8em;
        border-radius: 10px;
        top: -100px;
        filter: drop-shadow(0 0px 20px rgba(0, 0, 0, .1));
        width: 140px;
        display: none;
    }

    .ai-score-progress .user-chart-detail::after {
        content: '';
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        bottom: -10px;
        left: 60px;
    }

    .ai-score-progress:hover .user-chart-detail {
        display: block;
    }

    .progress {
        border-radius: 10px;
        height: 12px;
        font-size: .625rem;
    }

    .progress-bar {
        padding-top: 1px;
    }

    .ai-score-progress .user-chart-detail.pipeline {
        top: -130px;
    }

    .ai-score-progress .user-chart-detail.pipeline.in-bottom {
         top: unset;
         z-index: 1;
         bottom: -120px;
         filter: drop-shadow(0 0px 20px rgba(0, 0, 0, .1));
    }

    .ai-score-progress .user-chart-detail.in-bottom::after {
        border-top: 10px solid transparent;
        border-bottom: 10px solid #fff;
        bottom: unset;
        top: -20px;
    }

    .ai-score-progress {
        width: 100%;
    }
</style>
